<header class="tools-header">
    <h1>NoPlaceLeft.Tools</h1>
    <h2>A new experience for discovering tools within the network <strong>coming soon</strong>!</h2>

    <a mat-raised-button
       href="https://gapp.taethni.com"
       target="_blank">
        GAPP / GenMapper 2.0
    </a>
</header>

<main fxLayout="column"
      fxLayoutAlign="start center"
      class="mat-app-background">

    <section fxLayout="column">
        <header>
            <h2>Featured Tools</h2>
            <article fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="24px"
                     fxLayoutAlign="center center">
                <ng-container *ngFor="let tool of featured">
                    <app-tool-card [tool]="tool"></app-tool-card>
                </ng-container>
            </article>
        </header>
    </section>

    <mat-divider></mat-divider>
    <!--
    <section fxLayout="column">
        <header>
            <h2>Training</h2>
            <article fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="24px">
                <ng-container *ngFor="let tool of trainings">
                    <app-tool-card [tool]="tool"></app-tool-card>
                </ng-container>
            </article>
        </header>
    </section>

    <mat-divider></mat-divider>

    <section fxLayout="column">
        <header>
            <h2>GenMappers</h2>
            <article fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="24px">
                <ng-container *ngFor="let tool of GenMappers">
                    <app-tool-card [tool]="tool"></app-tool-card>
                </ng-container>
            </article>
        </header>
    </section> -->
</main>