<mat-toolbar color="primary"
             class="mat-elevation-z6">

    <img src="assets/npl-tools-logo-2018.png"
         height="50px"
         width="50px"
         alt="Npl Tools logo">
    <span (click)="reload()">NoPlaceLeft.Tools</span>
</mat-toolbar>

<router-outlet></router-outlet>
